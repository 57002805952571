<template>
  <section class="about-benefits">
    <div class="container">
      <div class="about-benefits__inner">
        <p class="about-benefits__title main__title">
          Наши преимущества
        </p>
        <div class="benefits__list">
          <div class="benefits__list-item">
            <p class="benefits__list-title main__title">
              Количество квалифицированных сотрудников
            </p>
            <p class="benefits__list-descr main__text">
              1101 сотрудников которые проходят ежегодные курсы на проверку и
              повышения своих знаний.
            </p>
            <div class="benefits__item-number">
              01
            </div>
          </div>
          <div class="benefits__list-item benefits__list-item--hide" />
          <div class="benefits__list-item benefits__list-item--hide" />
          <div class="benefits__list-item">
            <p class="benefits__list-title main__title">
              Скорость работы
            </p>
            <p class="benefits__list-descr main__text">
              По статистике, мы тратим на 20% меньше времени клиента, чем в
              среднем по отрасли. Естественно, храня при этом стабильно высокое
              качество услуг.
            </p>
            <div class="benefits__item-number">
              02
            </div>
          </div>
          <div class="benefits__list-item">
            <p class="benefits__list-title main__title">
              Поддержка и быстрое реагирование
            </p>
            <p class="benefits__list-descr main__text">
              Мы всегда на связи с клиентами и готовы дать исчерпывающие ответы
              на любые вопросы, связанные лабораторными испытаниями и другими
              техническими аспектами техосмотра.
            </p>
            <div class="benefits__item-number">
              03
            </div>
          </div>
          <div class="benefits__list-item benefits__list-item--hide" />
          <div class="benefits__list-item benefits__list-item--hide" />
          <div class="benefits__list-item">
            <p class="benefits__list-title main__title">
              Гарантия
            </p>
            <p class="benefits__list-descr main__text">
              Мы даем гарантию на производимые нами выполненные работы, так как
              для нас важно, чтобы клиент остался доволен
            </p>
            <div class="benefits__item-number">
              04
            </div>
          </div>
          <div class="benefits__list-item">
            <p class="benefits__list-title main__title">
              Новые технологии
            </p>
            <p class="benefits__list-descr main__text">
              Обновляем наше все оборудование с которым работают наши
              специалисты, для улучшения скорости выполнения Вашего заказа.
            </p>
            <div class="benefits__item-number">
              05
            </div>
          </div>
          <div class="benefits__list-item benefits__list-item--hide" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
    name: 'AboutBenefits'
}
</script>
